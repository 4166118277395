import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import modules from './modules';
import Offers from '@/services/api/Offers';
import storePlugins from '@/plugins/storePlugins';

// NB: Rearchitecured code.
import UtilsConfig from '@core/js/store/rearchitected/UtilsConfig.ts';
import { $api } from '@/services/backend/api';

Vue.use(Vuex);

// NB: No debug is needed in test and production environments.
const debug = !['test', 'production'].includes(process.env.NODE_ENV);
const buildOptions = (text = null, title = null, variant = 'orange', boldText = null, link = null, timer = 10000, linkText = null) => {
    return {
        id: new Date().getTime() + Math.floor(Math.random() * (100 - 1) + 1),
        title: title,
        variant: variant,
        boldText: boldText,
        text: text,
        link: link,
        timer: timer,
        linkText: linkText
    };
};

const store = new Vuex.Store({
    state: {
        packageVersion: 'v' + process.env.PACKAGE_VERSION || '',
        status: '',
        purchase: {},
        token: localStorage.getItem('token') || '',
        showVerificationModal: null,
        user: localStorage.getItem('user') !== '' && localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : '{}',
        organization:
            localStorage.getItem('organization') !== '' && localStorage.getItem('organization') !== 'undefined' // организация пользователя
                ? JSON.parse(localStorage.getItem('organization'))
                : '{}',
        organization_has_payments: false,
        fl44_without_limits: false,
        certificate_valid_to: null,
        certificate_need_to_be_prolonged: null,
        currentThumbprint: localStorage.getItem('thumbprint') !== '' && localStorage.getItem('thumbprint') !== 'undefined' ? localStorage.getItem('thumbprint') : null,
        isWorking: false,
        informationSection: [],
        toasts: [],
        offerDeclineStatuses: []
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading';
        },
        auth_success(state, token) {
            state.status = 'success';
            state.token = token;
            localStorage.setItem('token', token);
        },
        auth_error(state) {
            state.status = 'error';
            state.token = '';
        },
        logout(state) {
            state.status = '';
            state.token = '';
            state.user = {};
        },
        set_user(state, user) {
            state.user = user;
        },
        set_is_working(state, is_working) {
            state.isWorking = is_working;
        },
        set_organization(state, organization) {
            state.organization = organization;
        },
        set_organization_has_payments(state, organization_has_payments) {
            state.organization_has_payments = organization_has_payments;
        },
        set_fl44_without_limits(state, fl44_without_limits) {
            state.fl44_without_limits = fl44_without_limits;
        },
        set_certificate_valid_to(state, certificate_valid_to) {
            state.certificate_valid_to = certificate_valid_to;
        },
        set_certificate_need_to_be_prolonged(state, certificate_need_to_be_prolonged) {
            state.certificate_need_to_be_prolonged = certificate_need_to_be_prolonged;
        },
        set_purchase(state, purchase) {
            state.purchase = purchase;
        },
        set_current_thumbprint(state, thumbprint) {
            state.currentThumbprint = thumbprint;
            localStorage.setItem('thumbprint', thumbprint);
        },
        remove_current_certificate(state) {
            state.currentCertificateIndex = '';
            localStorage.removeItem('certificate_index');
            state.currentThumbprint = '';
            localStorage.removeItem('thumbprint');
        },
        setInformationToStore(state, payload) {
            state.informationSection = payload;
        },
        pushToast(state, payload) {
            state.toasts.unshift(payload);
        },
        removeToastFromStore(state, payload) {
            // console.log('close  '+payload)
            // console.timeEnd(payload);
            let index = state.toasts.findIndex((el) => el.id === payload);
            state.toasts.splice(index, 1);
        },
        updateOfferDeclineStatuses(state, offerDeclineStatuses) {
            state.offerDeclineStatuses = offerDeclineStatuses;
        },
        set_show_verification_modal(state, value) {
            state.showVerificationModal = value;
        }
    },
    actions: {
        login({ commit }, data) {
            localStorage.setItem('token', data.token);
            localStorage.setItem('user', JSON.stringify(data.user));
            localStorage.setItem('organization', JSON.stringify(data.organization));
            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
            commit('auth_success', data.token);
            commit('set_user', data.user);
            commit('set_show_verification_modal', data.show_verification_modal);
            commit('set_is_working', data.is_working);
            commit('set_organization', data.organization);
            commit('set_organization_has_payments', data.has_payments);
            commit('set_fl44_without_limits', data.fl44_without_limits ?? false);
            commit('set_certificate_valid_to', data.certificate_valid_to);
            commit('set_certificate_need_to_be_prolonged', data.certificate_need_to_be_prolonged);
        },
        set_purchase({ commit }, data) {
            commit('set_purchase', data);
        },
        logout({ commit }) {
            return new Promise((resolve) => {
                $api.authentication.logout().finally(() => {
                    commit('logout');
                    localStorage.removeItem('token');
                    localStorage.removeItem('show_verification_modal');
                    localStorage.removeItem('user');
                    localStorage.removeItem('organization');
                    localStorage.removeItem('certificate_index');
                    localStorage.removeItem('thumbprint');
                    delete window.axios.defaults.headers.common['Authorization'];
                    window.location.assign('/');
                    resolve();
                });
            });
        },
        getUserData({ commit }) {
            if (this.state.token !== '') {
                return new Promise((resolve, reject) => {
                    window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.state.token;
                    $api.authentication
                        .recall()
                        .then((resp) => {
                            localStorage.setItem('user', JSON.stringify(resp.data.user));
                            localStorage.setItem('organization', JSON.stringify(resp.data.organization));
                            commit('set_show_verification_modal', resp.data.show_verification_modal);
                            commit('set_organization', resp.data.organization);
                            commit('set_organization_has_payments', resp.data.has_payments);
                            commit('set_fl44_without_limits', resp.data.fl44_without_limits ?? false);
                            commit('set_certificate_valid_to', resp.data.certificate_valid_to);
                            commit('set_certificate_need_to_be_prolonged', resp.data.certificate_need_to_be_prolonged);
                            commit('set_user', resp.data.user);
                            commit('set_organization', resp.data.organization);
                            commit('set_is_working', resp.data.is_working);
                            commit('rearchitected/users/setUser', { user: resp.data.user, organization: resp.data.organization });

                            resolve(resp);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        setInformation({ commit }, payload) {
            commit('setInformationToStore', payload);
        },
        addToast({ commit }, payload) {
            commit('pushToast', payload);
        },
        removeToast({ commit }, payload) {
            commit('removeToastFromStore', payload);
        },
        storeAddToast({ commit }, payload) {
            commit('pushToast', payload);
        },
        pushToast({ dispatch }, payload) {
            payload['id'] = new Date().getTime() + Math.floor(Math.random() * (100 - 1) + 1);
            dispatch('storeAddToast', payload);
            // this.storeAddToast(payload);
        },
        showSuccessToast({ dispatch }, message) {
            dispatch('pushToast', buildOptions(message, 'Успех', 'green'));
        },
        showDangerToast({ dispatch }, message) {
            dispatch('pushToast', buildOptions(message, 'Ошибка', 'red'));
        },
        showError({ dispatch }, error) {
            console.error(error);
            let errorCode = error.message.replace(/\D+/g, '');
            let errorType = +errorCode[0];
            if (422 === +errorCode) {
                let err = error.response.data.errors;
                for (let key in err) {
                    dispatch('pushToast', buildOptions(err[key].join(', '), 'Ошибка', 'red'));
                }
            } else if (401 === +errorCode) {
                dispatch('pushToast', buildOptions('Попытка неавторизованного доступа', 'Ошибка', 'red'));
            } else if (403 === +errorCode) {
                dispatch('pushToast', buildOptions(error.response.data.message, 'Ошибка', 'red'));
            } else if (404 === +errorCode) {
                dispatch('pushToast', buildOptions('Не найдено', 'Ошибка', 'red'));
            } else if (5 === errorType) {
                dispatch('pushToast', buildOptions('Сервер не отвечает', 'Ошибка', 'red'));
            } else {
                dispatch('pushToast', buildOptions('Неизвестная ошибка, обратитесь в тех. поддержку', 'Ошибка', 'red'));
            }
        },

        fetchOfferDeclineStatuses({ commit }) {
            Offers.getDeclineStatuses().then((response) => {
                commit('updateOfferDeclineStatuses', response.data);
            });
        }
    },
    getters: {
        appVersion: (state) => {
            return state.packageVersion;
        },
        isLoggedIn: (state) => !!state.token,
        isProvider: (state) => {
            if (!!state.token && state.organization.is_provider) {
                return state.organization.is_provider;
            } else {
                return false;
            }
        },
        isProductionsAllowed: (state, getters) => {
            return getters.isLoggedIn && getters.isProvider && state.organization.productions_allowed;
        },
        token: (state) => state.token,
        authStatus: (state) => state.status,
        getCurrentThumbprint: (state) => state.currentThumbprint,
        getPurchase: (state) => state.purchase,
        getInformation: (state) => state.informationSection,
        getUser: (state) => state.user,
        getOrganization: (state) => state.organization,
        getOrganizationHasPayments: (state) => state.organization_has_payments,
        customerCanCreateHiddenPurchase: (state) => state.organization.can_create_hidden_purchases,
        getToasts: (state) => state.toasts,
        getCanSign: (state) => state.user?.can_sign,
        offerDeclineStatuses: (state) => state.offerDeclineStatuses,
        getCertificateNeedToBeProlonged: (state) => state.certificate_need_to_be_prolonged
    },
    strict: debug,
    plugins: debug ? [createLogger(), storePlugins, UtilsConfig.initialize] : [storePlugins, UtilsConfig.initialize], // set logger only for development
    modules
});

export default store;
