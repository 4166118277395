import { render, staticRenderFns } from "./button-close-modal.vue?vue&type=template&id=4e61c2c1&scoped=true&"
import script from "./button-close-modal.vue?vue&type=script&lang=js&"
export * from "./button-close-modal.vue?vue&type=script&lang=js&"
import style0 from "./button-close-modal.vue?vue&type=style&index=0&id=4e61c2c1&lang=css&"
import style1 from "./button-close-modal.vue?vue&type=style&index=1&id=4e61c2c1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e61c2c1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/stage-ext/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4e61c2c1')) {
      api.createRecord('4e61c2c1', component.options)
    } else {
      api.reload('4e61c2c1', component.options)
    }
    module.hot.accept("./button-close-modal.vue?vue&type=template&id=4e61c2c1&scoped=true&", function () {
      api.rerender('4e61c2c1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/button-close-modal.vue"
export default component.exports