'use strict';

import Config from '@core/js/ddriven/application/config/Config';
import FormatDate from '@lib/js/src/misc/FormatDate';
import X509SignatureVO, { IRawContractDisagreementSignatureData } from '@core/js/ddriven/domain/model/common/X509Signature.valueobject';
import FileAttachmentVO, { IRawFileAttachmentData } from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import DisputeResolutionRoundVO from './dispute/DisputeResolutionRoundVO.valueobject';
import { IRawDisagreementData } from './dispute/DisputeResolutionEvent.valueobject';
import { Types as UserTypes } from '@core/js/ddriven/domain/model/users/User.valueobject';
import AnnexItemVO, { AnnexStatuses, IRawAnnexItemData } from './annex/AnnexItem.valueobject';
import DeliverableItemVO, { IRawContractDeliverableItem } from '@core/js/ddriven/domain/model/common/deliverable/DeliverableItem.valueobject';
import SupplierRequirementsVO, { IRawSupplierRequirements, IRawSupplierRequirementsDocument, TSelfPOJO as SRTSelfPOJO } from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SupplierRequirements.valueobject';
import SRDA31s1p1SupportingDocumentVO from '../purchases/update/supplier-requirements/SRDA31s1p1SupportingDocument.valueobject';
import SupplierRequirementDetailsA31s1p1VO from '../purchases/update/supplier-requirements/SupplierRequirementDetailsA31s1p1.valueobject';
import { IRawKBKLimit } from '../purchases/update/KBKLimitSpecificationItemEditable.valueobject';
import KBKLimitsSpecificationItemsEditableCollection from '../purchases/update/KBKLimitsSpecificationItemsEditable.collection';
import roundToTwoDecimals from '@lib/js/src/misc/roundToTwoDecimals';
import SupplierRequirementAdditionalVO from '../purchases/update/supplier-requirements/SupplierRequirementAdditional.valueobject';
import SupplierAccountingVO from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SupplierAccounting.valueobject';

export interface IRawContractDataFromPurchase {
    id: number;
    contract_status: string;
    contract_status_id: number;
    updated_at: string;
    customer: IRawContractDisagreementSignatureData;
    provider: IRawContractDisagreementSignatureData;
    contract_files: IRawFileAttachmentData[];
    contract_confirmation_files?: IRawFileAttachmentData[];

    finalOffer?: {
        total_cost: number;
        offer_cost: number;
        price_wo_vat: number;
        delivery_cost: number;
        items: IRawContractDeliverableItem[];
        supplier_requirements: IRawSupplierRequirements;
    };
    contract_disagreement_files: IRawDisagreementData[];
    // NB: Artificial property I created manually in store action for convenient
    // initialization as it should have been organized in API response;
    annexes: IRawAnnexItemData[];
    fulfillment_attachments: IRawFulfillmentAttachmentData[];
    supplier_requirements?: IRawSupplierRequirements;
    limits: IRawKBKLimit[];
    supplier_accounting?: SupplierAccountingVO;
    canProviderDisagreement: boolean;
}

interface IRawFulfillmentAttachmentData {
    created_at: string;
    id: number;
    file: IRawFileAttachmentData;
}

export default class ContractItemDetailsVO {
    id: number;
    price_total: number | null;
    offer_cost: number | null;
    price_wo_vat: number | null;
    delivery_cost: number | null;
    acceptance_status_title: string;
    acceptance_status_id: number;
    acceptance_status_updated_at: string;
    signatures: { customer: X509SignatureVO; supplier: X509SignatureVO };
    attachments: {
        main: FileAttachmentVO[];
        confirmation: FileAttachmentVO[];
    };
    deliverables: DeliverableItemVO[];
    kbk_limits: KBKLimitsSpecificationItemsEditableCollection;
    disputes: DisputeResolutionRoundVO[];
    annexes: AnnexItemVO[];
    fulfillment_attachments: FileAttachmentVO[];
    supplier_requirements: SupplierRequirementsVO;
    supplier_accounting: SupplierAccountingVO;
    can_provider_send_dispute: boolean;

    constructor(data: IRawContractDataFromPurchase) {
        const timezone = Config.get('timezone.customer') as string;

        this.id = data.id;

        this.price_total = data.finalOffer ? data.finalOffer.total_cost : null;
        this.offer_cost = data.finalOffer ? data.finalOffer.offer_cost : null;
        this.price_wo_vat = data.finalOffer ? data.finalOffer.price_wo_vat : null;
        this.delivery_cost = data.finalOffer ? data.finalOffer.delivery_cost : null;

        this.acceptance_status_title = data.contract_status;
        this.acceptance_status_id = data.contract_status_id;
        this.acceptance_status_updated_at = FormatDate.dateFromISOUTCToTimezoned(data.updated_at, timezone);
        this.signatures = {
            customer: new X509SignatureVO(UserTypes.customer, data.customer),
            supplier: new X509SignatureVO(UserTypes.supplier, data.provider)
        };
        this.attachments = {
            main: FileAttachmentVO.fromArray(data.contract_files),
            confirmation: data.contract_confirmation_files ? FileAttachmentVO.fromArray(data.contract_confirmation_files) : []
        };
        this.deliverables = data.finalOffer ? this.factoryDeliverables(data.finalOffer.items) : [];
        this.kbk_limits = data.limits ? KBKLimitsSpecificationItemsEditableCollection.fromAPIResponse(data.limits) : new KBKLimitsSpecificationItemsEditableCollection();
        this.disputes = this.factoryDisputes(data.contract_disagreement_files);
        this.annexes = AnnexItemVO.fromArray(data.annexes);
        this.fulfillment_attachments = data.fulfillment_attachments ? this.factoryFulfillmentAttachments(data.fulfillment_attachments) : [];
        this.supplier_requirements = this.supplierRequirements(data.supplier_requirements);
        this.supplier_accounting = SupplierAccountingVO.fromArray(data.supplier_accounting);
        this.can_provider_send_dispute = data.canProviderDisagreement ?? false;
    }

    get deliverables_total_amount() {
        return roundToTwoDecimals(
            this.deliverables.reduce((accumulator, deliverable: DeliverableItemVO) => {
                return (accumulator += deliverable.price_total ?? 0);
            }, 0)
        );
    }

    get has_limits_errors(): boolean {
        return this.kbk_limits.hasErrors() || this.deliverables_total_amount !== this.kbk_limits.distributed_amount_total;
    }

    get has_supplier_accounting_errors(): boolean {
        return this.supplier_accounting.hasErrors();
    }

    public pendingAnnex(): AnnexItemVO | null {
        const annex = this.annexes.find((annex: AnnexItemVO) => {
            return annex.isPending();
        });
        return annex ?? null;
    }

    public isActualAnnex(annex: AnnexItemVO): boolean {
        if (!this.hasAnnexes() || annex.status_id !== AnnexStatuses.SignedByBoth) {
            return false;
        }

        const latest = this.annexes
            .filter((annex: AnnexItemVO) => {
                return annex.status_id === AnnexStatuses.SignedByBoth;
            })
            .sort((annexA: AnnexItemVO, annexB: AnnexItemVO) => {
                return annexB.id! - annexA.id!;
            });

        return latest[0] && latest[0].id === annex.id ? true : false;
    }

    public isSignedByBoth(): boolean {
        return this.signatures.customer.hasSignature() && this.signatures.supplier.hasSignature();
    }

    public isSignedByCustomer(): boolean {
        return this.signatures.customer.hasSignature();
    }

    public isSignedBySupplier(): boolean {
        return this.signatures.supplier.hasSignature();
    }

    /**
     * Is the contract in a contract acceptance stage called "customer propposed a contract".
     */
    public isProposed(): boolean {
        return this.acceptance_status_id === 1;
    }

    /**
     * Is the contract in a contract acceptance stage called "supplier raised the dispute on contract".
     */
    public isDisputed(): boolean {
        return this.acceptance_status_id === 4;
    }

    public hasDisputes(): boolean {
        return this.disputes.length > 0;
    }

    public hasAnnexes(): boolean {
        return this.annexes.length > 0;
    }

    public hasPendingAnnexes(): boolean {
        return this.annexes.some((annex: AnnexItemVO) => {
            return annex.isPending();
        });
    }

    public hasFulfillment(): boolean {
        return this.fulfillment_attachments.length > 0;
    }

    public hasDeliveryCost(): boolean {
        return this.delivery_cost !== null && this.delivery_cost > 0;
    }

    public actualMainAttachment(): FileAttachmentVO | null {
        return (
            this.attachments.main.find((attachment: FileAttachmentVO) => {
                return !attachment.is_archived;
            }) ?? null
        );
    }

    private factoryDisputes(dataArray: IRawDisagreementData[]): DisputeResolutionRoundVO[] {
        return dataArray.map((data: IRawDisagreementData) => {
            return new DisputeResolutionRoundVO(data);
        });
    }

    private factoryDeliverables(dataArray: IRawContractDeliverableItem[]): DeliverableItemVO[] {
        return dataArray.map((data: IRawContractDeliverableItem) => {
            return DeliverableItemVO.fromContractItem(data);
        });
    }

    private factoryFulfillmentAttachments(dataArray: IRawFulfillmentAttachmentData[]): FileAttachmentVO[] {
        return dataArray.map((data: IRawFulfillmentAttachmentData) => {
            const tempData: IRawFileAttachmentData = {
                id: data.file.id,
                name: data.file.name,
                attachment_record_id: data.id,
                created_at: data.created_at,
                type: data.file.type
            };
            return new FileAttachmentVO(tempData);
        });
    }

    private supplierRequirements(data?: IRawSupplierRequirements): SupplierRequirementsVO {
        const pojo: SRTSelfPOJO = {
            is_only_smb: false,
            supplier_reqs_sonko: false,
            a31s1ps3_5ps7_11: data?.supplier_reqs_b ?? false,
            a31s1p1: data?.supplier_reqs_a ?? false,
            a31s1_1: data?.supplier_reqs_c ?? false,
            a31s1p1_details: {
                description: data?.supplier_reqs_a_text,
                supporting_documents: data?.supplier_required_documents.map((document: IRawSupplierRequirementsDocument) => {
                    return {
                        id: document.id,
                        description: document.name ?? document.document_name,
                        attachment: document.storedDocument ? FileAttachmentVO.fromPOJO({ file_id: document.storedDocument.id, name: document.storedDocument.name, storage: 's3' }) : {}
                    } as SRDA31s1p1SupportingDocumentVO;
                })
            } as SupplierRequirementDetailsA31s1p1VO,
            rating: false,
            additional: new SupplierRequirementAdditionalVO(data?.additional)
        };

        return new SupplierRequirementsVO(pojo);
    }
}
