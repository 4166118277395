'use strict';

import Config from '@core/js/ddriven/application/config/Config';
import FormatDate from '@lib/js/src/misc/FormatDate';
import OrganizationVO, { IPurchaseOffersListOrganizationData, IRawOrganizationData } from '@core/js/ddriven/domain/model/users/Organization.valueobject';
import X509SignatureVO, { IRawContractDisagreementSignatureData } from '@core/js/ddriven/domain/model/common/X509Signature.valueobject';
import { Types as UserTypes } from '@core/js/ddriven/domain/model/users/User.valueobject';
import DeliverableItemVO, { IRawNormalizedDeliverableItem } from '@core/js/ddriven/domain/model/common/deliverable/DeliverableItem.valueobject';
import FileAttachmentVO, { IRawFileAttachmentData } from '../../../common/FileAttachment.valueobject';
import SupplierRequirementsVO, { IRawSupplierRequirements, IRawSupplierRequirementsDocument, TSelfPOJO as SRTSelfPOJO } from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SupplierRequirements.valueobject';
import SRDA31s1p1SupportingDocumentVO from '../../update/supplier-requirements/SRDA31s1p1SupportingDocument.valueobject';
import SupplierRequirementDetailsA31s1p1VO from '../../update/supplier-requirements/SupplierRequirementDetailsA31s1p1.valueobject';
import StandardDictionaryItem from '../../../common/dictionaries/StandardDictionaryItem.valueobject';
import SupplierRequirementAdditionalVO, { TRawAdditional } from '../../update/supplier-requirements/SupplierRequirementAdditional.valueobject';

export interface IRawStatusItem {
    id: string;
    title: string;
}

export interface IRawProposalItemDataForGuest {
    status: IRawStatusItem;
    created_at: string;
    total_cost: number;
}

export interface IRawProposalItemDataForSupplier {
    id: number;
    status: IRawStatusItem;
    created_at: string;
    total_cost: number;
    offer_cost: number;
    price_wo_vat: number;
    delivery_cost: number;
    my: boolean;
    x509certificate: string[];
}

export interface IRawProposalItemDataForCustomer {
    organization: IRawOrganizationData;
    status: IRawStatusItem;
    items: IRawNormalizedDeliverableItem[];
    documents: IRawFileAttachmentData[];
    contacts?: IRawOrganizationData['contacts'];
    supplier_requirements?: IRawSupplierRequirements;
    reply_supplier_reqs_a?: boolean;
    reply_supplier_reqs_b?: boolean;
    reply_supplier_reqs_c?: boolean;
    reply_supplier_reqs_sonko?: boolean;
    supplier_required_documents?: IRawSupplierRequirementsDocument[];
    is_declined?: boolean;
    decline_status_name?: string;
    organization_card_attachment?: IRawFileAttachmentData;
}

export type IRawProposalItemData = IRawProposalItemDataForGuest & IRawProposalItemDataForSupplier & IRawProposalItemDataForCustomer;

export default class ProposalItemVO {
    submitted_at: string;
    total_price: number;
    offer_cost: number;
    price_wo_vat: number;
    delivery_cost: number;

    id: number | null;
    is_best: boolean;
    is_current_supplier_proposal: boolean;
    is_declined: boolean;
    status: IRawStatusItem;
    decline_reason: string | null;
    deliverables: DeliverableItemVO[] | [];
    attachments: FileAttachmentVO[] | [];
    supplier_requirements: SupplierRequirementsVO;
    supplier: OrganizationVO | null;
    signature: X509SignatureVO;
    organization_card_attachment: FileAttachmentVO | null;

    constructor(rawProposalItemData: IRawProposalItemData, isBest?: boolean) {
        const timezone = Config.get('timezone.customer') as string;

        this.submitted_at = FormatDate.dateNormalizedTimezoned(rawProposalItemData.created_at, timezone);
        this.total_price = rawProposalItemData.total_cost;
        this.offer_cost = rawProposalItemData.offer_cost;
        this.price_wo_vat = rawProposalItemData.price_wo_vat;
        this.delivery_cost = rawProposalItemData.delivery_cost;
        this.id = rawProposalItemData.id ?? null;
        this.status = rawProposalItemData.status;
        this.is_best = isBest ?? false;
        this.is_current_supplier_proposal = rawProposalItemData.my ?? false;
        this.is_declined = rawProposalItemData.is_declined ?? false;
        this.decline_reason = rawProposalItemData.decline_status_name ?? null;

        this.deliverables = rawProposalItemData.items
            ? rawProposalItemData.items.map((item: IRawNormalizedDeliverableItem) => {
                  return new DeliverableItemVO(item);
              })
            : [];

        this.attachments = rawProposalItemData.documents
            ? rawProposalItemData.documents.map((rawAttachmentData: IRawFileAttachmentData) => {
                  return new FileAttachmentVO(rawAttachmentData);
              })
            : [];

        this.supplier_requirements = this.buildSupplierRequirements(rawProposalItemData);

        this.supplier = rawProposalItemData.organization ? new OrganizationVO(rawProposalItemData.organization, true) : null;
        this.signature = rawProposalItemData.x509certificate && this.buildSignature(rawProposalItemData);
        this.organization_card_attachment = rawProposalItemData.organization_card_attachment ? new FileAttachmentVO(rawProposalItemData.organization_card_attachment) : null;
    }

    public hasAttachments(): boolean {
        return this.attachments.length > 0;
    }

    public decline(reasonId: number, reasons: StandardDictionaryItem[]): void {
        this.is_declined = true;
        this.decline_reason = StandardDictionaryItem.findById(reasonId, reasons)?.title ?? null;
    }

    public static fromArray(rawProposalItemDataArray: IRawProposalItemData[]): ProposalItemVO[] {
        return rawProposalItemDataArray
            .sort((firstItem: IRawProposalItemData, secondItem: IRawProposalItemData) => {
                return firstItem.total_cost - secondItem.total_cost;
            })
            .map((item: IRawProposalItemData, index) => {
                item.contacts && (item.organization.contacts = item.contacts);
                // REFACTOR: remove this normalization when/if API response is normalized.
                const organizationDataSource = item.organization ?? item;
                item.organization = ProposalItemVO.prepareRawOrganizationData(organizationDataSource as unknown as IPurchaseOffersListOrganizationData);
                return new ProposalItemVO(item, index === 0);
            });
    }

    private static prepareRawOrganizationData(data?: IPurchaseOffersListOrganizationData): IRawOrganizationData {
        return {
            id: data?.organization_id ?? data?.id ?? (null as any),
            is_provider: true,
            full_name: data?.full_name,
            short_name: data?.short_name,
            inn: data?.inn,
            kpp: data?.kpp,
            region_code: data?.region_code,
            rating: data?.rating ?? (null as any),
            focus_red: data?.focus_red ?? false,
            focus_yellow: data?.focus_yellow ?? false,
            focus_green: data?.focus_green ?? false,
            contacts: data?.contacts
        };
    }

    private buildSignature(data: IRawProposalItemData): X509SignatureVO {
        const interimData: IRawContractDisagreementSignatureData = {
            organization_title: this.supplier?.title_brief as string,
            owner: '',
            x509certificate: {
                '0': data.x509certificate[0]
            },
            x509certificateDate: this.submitted_at
        };
        return new X509SignatureVO(UserTypes.supplier, interimData);
    }

    private buildSupplierRequirements(data: IRawProposalItemData): SupplierRequirementsVO {
        const pojo: SRTSelfPOJO = {
            is_only_smb: false, // Default value not used here.
            supplier_reqs_sonko: !!data?.reply_supplier_reqs_sonko,
            a31s1ps3_5ps7_11: !!data?.reply_supplier_reqs_b,
            a31s1p1: !!data?.reply_supplier_reqs_a,
            a31s1_1: !!data?.reply_supplier_reqs_c,
            a31s1p1_details: {
                description: null,
                supporting_documents: data.supplier_required_documents?.map((document: IRawSupplierRequirementsDocument) => {
                    // REFACTOR: remove the need to normalize the property name as the API response format is made uniform.
                    const attachmentProperty = document.stored_document ?? document.storedDocument;
                    return {
                        id: document.id,
                        description: document.name ?? document.document_name,
                        attachment: attachmentProperty ? FileAttachmentVO.fromPOJO({ file_id: attachmentProperty.id as string, name: attachmentProperty.name as string, storage: 's3' }) : {}
                    } as SRDA31s1p1SupportingDocumentVO;
                })
            } as SupplierRequirementDetailsA31s1p1VO,
            rating: false,
            additional: new SupplierRequirementAdditionalVO(data.supplier_requirements?.additional as unknown as TRawAdditional)
        };

        return new SupplierRequirementsVO(pojo);
    }
}
