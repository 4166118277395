import { render, staticRenderFns } from "./ConfirmContractAnnexDeliverableDuplicateRemovePopup.view.vue?vue&type=template&id=0db4568b&"
import script from "./ConfirmContractAnnexDeliverableDuplicateRemovePopup.view.vue?vue&type=script&lang=js&"
export * from "./ConfirmContractAnnexDeliverableDuplicateRemovePopup.view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/stage-ext/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0db4568b')) {
      api.createRecord('0db4568b', component.options)
    } else {
      api.reload('0db4568b', component.options)
    }
    module.hot.accept("./ConfirmContractAnnexDeliverableDuplicateRemovePopup.view.vue?vue&type=template&id=0db4568b&", function () {
      api.rerender('0db4568b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/rearchitected/themes/atmo/views/overlays/popups/purchases/contract/annex/ConfirmContractAnnexDeliverableDuplicateRemovePopup.view.vue"
export default component.exports